import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import Header from "@components/header";

// Assets
import { ReactComponent as DataNetwork } from "@images/data-sharing.svg";
import { ReactComponent as TrustNetwork } from "@images/trust-network-lg.svg";
import { getClassNames } from "@/utils/helpers";

const ExploreTheNetwork = () => {
  const navigate = useNavigate();
  return (
    <div className="gradient-7 relative">
      <div className="-z-10 absolute inset-0 bg-trust-network bg-cover blur-[27px]"></div>
      <div className="mx-auto h-screen flex flex-col">
        <Header title="Explore the network" />
        <div className="container-auth flex-1 flex items-center justify-center gap-6">
          <div
            className={getClassNames(
              "gradient-6 border border-[#c5c9cd] radius-1 min-h-[550px] w-full max-w-[456px]",
              "flex flex-col relative overflow-hidden cursor-pointer"
            )}
            onClick={() => navigate("/overview")}
          >
            <div className="gradient-4 absolute inset-0 z-0 bg-center" />
            <h1 className="header3 border-header text-white px-10 h-16 z-10">
              Overview of the network
            </h1>
            <p className="px-10 mt-4 paragraph-large text-white z-10 flex-1">
              Discover how organizations operate on the Trust Network to
              facilitate network intelligence
            </p>
            <TrustNetwork className="z-10 mx-auto mb-10" />
          </div>

          <div
            className={getClassNames(
              "gradient-6 border border-[#c5c9cd] radius-1 min-h-[550px] w-full max-w-[456px]",
              "flex flex-col relative overflow-hidden cursor-pointer"
            )}
            onClick={() => navigate("/data-sharing")}
          >
            <div className="gradient-4 absolute inset-0 z-0 bg-center" />
            <h1 className="header3 border-header text-white px-10 h-16 z-10">
              Data sharing on the network
            </h1>
            <p className="px-10 mt-4 paragraph-large text-white z-10 flex-1">
              Deep dive on how the Trust Network achieves confidentiality and
              privacy preserving data sharing
            </p>
            <DataNetwork className="z-10 mx-auto mb-20" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreTheNetwork;
