import { useNavigate } from "react-router-dom";
import Header from "@components/header";

import TrudentyWorldlineTrailerValueProposition from "@assets/videos/TrudentyWorldlineTrailerValueProposition.webm";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-auto h-screen flex flex-col">
      <Header title="" isHome showBackButton={false} />
      <div className="absolute inset-0 z-0 cursor-pointer">
        <video
          autoPlay
          autoFocus
          loop
          muted
          onClick={() => navigate("/menu")}
          className="object-cover w-full h-full cursor-pointer"
        >
          <source
            src={`${TrudentyWorldlineTrailerValueProposition}?loop=3`}
            type="video/webm"
          />
        </video>
      </div>
    </div>
  );
};

export default Home;
//
