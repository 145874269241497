import { Link } from "react-router-dom";

// Components
import Header from "@components/header";

const SelectDemo = () => {
  const bullets = [
    "Prevent first-party fraud",
    "Improve customer experience and loyalty",
    "Eliminate manual work from refund flows",
  ];

  return (
    <div className="gradient-3 relative">
      <div className="-z-10 absolute inset-0 bg-product-select blur-[17px]"></div>
      <div className="mx-auto h-screen flex flex-col">
        <Header />
        <h1 className="container-auth text-[2.5rem] leading-[44px] font-medium mt-7 mb-5">
          Select a product demo
        </h1>
        <div className="container-auth flex w-full gap-6">
          <Link to="/use-cases" className="w-1/3">
            <div className="bg-box-merchant bg-no-repeat bg-cover radius-1 min-h-[550px] w-full flex flex-col justify-between relative overflow-hidden px-10 pt-5 pb-[52px]">
              <div className="gradient-4 absolute inset-0 z-0 bg-center" />
              <div className="relative">
                <h1 className="header3 border-header text-white mb-9 z-10">
                  Console
                </h1>
                <p className=" mb-6 paragraph-large text-white z-10">
                  Use the Trudenty merchant console to explore the use-cases;
                </p>
                <div className="px-5">
                  <ul className="relative flex flex-col gap-y-2.5 list-none">
                    {bullets.map((bullet) => (
                      <li
                        key={bullet}
                        className="bulleted paragraph-large text-white"
                      >
                        {bullet}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="py-4 bg-grey-280 rounded-full w-full text-center text-white z-10 header5">
                Select demo
              </div>
            </div>
          </Link>
          <a
            href="https://experience.consumeriec.trudenty.com"
            className="w-1/3"
          >
            <div className="bg-person-consumer bg-no-repeat bg-cover radius-1 min-h-[550px] w-full flex flex-col justify-between relative overflow-hidden px-10 pt-5 pb-[52px]">
              <div className="gradient-4 absolute inset-0 z-0 bg-center" />
              <div className="relative">
                <h1 className="header3 border-header text-white mb-9 z-10">
                  Consumer
                </h1>
                <p className=" mt-4 paragraph-large text-white z-10 py-4">
                  Explore the resulting consumer experience for the different
                  use cases
                </p>
              </div>
              <div className="py-4 bg-grey-280 rounded-full w-full text-center text-white z-10 header5">
                Select demo
              </div>
            </div>
          </a>
          <div className="min-h-[550px] max-h-full flex flex-col gap-6 justify-between w-1/3">
            <a
              href="https://trudenty.com/get-started"
              className="h-[263px] block"
            >
              <div className="gradient-6 bg-box-merchant bg-no-repeat bg-cover radius-1 w-full flex flex-col justify-between relative overflow-hidden px-10 pt-5 pb-7 h-full">
                <div className="gradient-4 absolute inset-0 z-0 bg-center" />
                <div className="relative">
                  <h1 className="header3 border-header text-white mb-9 z-10">
                    Sandbox
                  </h1>
                  <p className=" paragraph-large text-white z-10">
                    Ready to compute Trust Index scores and test integrations?
                    Start here.
                  </p>
                </div>
                <div className="py-4 bg-grey-280 rounded-full w-full text-center text-white z-10 header5">
                  Request access
                </div>
              </div>
            </a>
            <a href="https://trudenty.com/docs" className="h-[263px] block">
              <div className="gradient-6 bg-box-merchant bg-no-repeat bg-cover radius-1  w-full flex flex-col justify-between relative overflow-hidden px-10 pt-5 pb-7 h-full">
                <div className="gradient-4 absolute inset-0 z-0 bg-center" />
                <div className="relative">
                  <h1 className="header3 border-header text-white mb-9 z-10">
                    Documentation
                  </h1>
                  <p className="paragraph-large text-white z-10">
                    Access our API documentation for integration guidance and
                    API testing.
                  </p>
                </div>
                <div className="py-4 bg-grey-280 rounded-full w-full text-center text-white z-10 header5">
                  Go to documentation
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDemo;
