// Components
import Header from "@components/header";

// Assets
import { ReactComponent as ShopperTrustIndex } from "@images/sti-illustration.svg";
import { ReactComponent as PaymentPersonalisation } from "@images/payment-personalisation-illustration.svg";
import { ReactComponent as CheckoutRefundManager } from "@images/crm-illustration.svg";

const UseCasesGuide = () => {
  return (
    <div className="gradient-3 relative">
      <div className="-z-10 absolute inset-0 bg-product-select blur-[17px]"></div>
      <div className="mx-auto h-screen flex flex-col">
        <Header title="Use cases and benefits" showBackButton showDemoLink />
        <div className="container-auth flex-1 flex items-center justify-center gap-6">
          <div>
            <div className="gradient-6 radius-1 min-h-[550px] w-full max-w-[412px] flex flex-col relative overflow-hidden px-10 pt-5">
              <div className="gradient-4 absolute inset-0 z-0 bg-center" />
              <div className="relative">
                <h1 className="header3 border-header text-white h-16 mb-10 z-10 flex items-start">
                  Prevent first-party fraud
                </h1>
                <div className=" flex flex-col justify-center min-h-[140px]">
                  <p className="paragraph-large text-white z-10 flex-1">
                    Embed consumer fraud risk intelligence to prevent fraud
                  </p>
                </div>
              </div>
              <a
                href="https://www.notion.so/trudenty/Consumer-fraud-risk-85ed7f1b936a4a488440515f5e301234?pvs=4"
                className="py-4 bg-grey-280/50 rounded-full w-full text-center text-white z-50 header5"
              >
                Learn more
              </a>
              <ShopperTrustIndex className="z-10 mx-auto absolute bottom-0 right-0" />
            </div>
          </div>
          <div>
            <div className="gradient-6 radius-1 min-h-[550px] w-full max-w-[412px] flex flex-col relative overflow-hidden px-10 pt-5">
              <div className="gradient-4 absolute inset-0 z-0 bg-center" />
              <div className="relative">
                <h1 className="header3 border-header text-white h-16 mb-10 z-10 flex items-start">
                  Improve customer experience and loyalty
                </h1>
                <div className="flex flex-col justify-center min-h-[140px]">
                  <p className="paragraph-large text-white z-10 flex-1">
                    Offer enhanced checkout and refunds experiences to trusted
                    consumers
                  </p>
                </div>
              </div>
              <a
                href=" https://www.notion.so/trudenty/Personalise-payments-9339bd9267fd4180b15325913b1f729b?pvs=4"
                className="py-4 bg-grey-280/50 rounded-full w-full text-center text-white z-50 header5"
              >
                Learn more
              </a>
              <PaymentPersonalisation className="z-10 mx-auto absolute bottom-0 right-0" />
            </div>
          </div>
          <div>
            <div className="gradient-6 radius-1 min-h-[550px] w-full max-w-[412px] flex flex-col relative overflow-hidden px-10 pt-5">
              <div className="gradient-4 absolute inset-0 z-0 bg-center" />
              <div className="relative">
                <h1 className="header3 border-header text-white h-16 z-10 mb-10 flex items-start">
                  Eliminate manual work from refund flows
                </h1>
                <div className=" flex flex-col justify-center min-h-[140px]">
                  <p className=" paragraph-large text-white z-10 flex-1">
                    Automate refund decisioning for trusted consumers, and
                    leverage consumer intelligence to assist decisions for high
                    risk consumers
                  </p>
                </div>
              </div>
              <a
                href="https://www.notion.so/trudenty/Instant-refunds-77fe86a59c9e4236a1c01578158020b7?pvs=4"
                className="py-4 bg-grey-280/50 rounded-full w-full text-center text-white z-50 header5"
              >
                Learn more
              </a>
              <CheckoutRefundManager className="z-10 mx-auto absolute bottom-0 right-0" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCasesGuide;
